import { __rest as e, __assign as i } from "../../../../_virtual/_tslib.js";
import { jsxs as r, jsx as a } from "react/jsx-runtime";
import { NumericFormat as t } from "react-number-format";
import { Box as l } from "@mui/material";
import { Typography as n } from "../../../typography/typography.component.js";
import { StyledBanner as o, StyledTypography as c, StyledArrowIcon as m, StyledBox as d } from "./super-rate.styles.js";
import s from "../../../../packages/pwa-ui/assets/images/arrow-colored.image.svg.js";

var p = function (p) {
  var h = p.heading,
      g = p.subHeading,
      u = p.oldRate,
      f = p.newRate,
      v = p.isWithArrows,
      y = void 0 !== v && v,
      x = p.image,
      w = e(p, ["heading", "subHeading", "oldRate", "newRate", "isWithArrows", "image"]);
  return r(o, i({}, w, {
    children: [r(l, i({
      textAlign: "left"
    }, {
      children: [h, g, r(l, i({
        alignItems: "baseline",
        display: "flex"
      }, {
        children: [u && a(l, i({
          display: "inline-block",
          mr: 1
        }, {
          children: a(t, {
            decimalScale: 3,
            decimalSeparator: ",",
            displayType: "text",
            fixedDecimalScale: !0,
            readOnly: !0,
            renderText: function (e) {
              if (void 0 !== e) {
                var r = e.replace(/(\d),?0$/, "$1");
                return a(c, i({
                  color: "white",
                  component: "span",
                  variant: "title2"
                }, {
                  children: r
                }));
              }
            },
            value: u,
            valueIsNumericString: !0
          })
        })), a(t, {
          decimalScale: 3,
          decimalSeparator: ",",
          displayType: "text",
          fixedDecimalScale: !0,
          readOnly: !0,
          renderText: function (e) {
            if (void 0 !== e) return a(n, i({
              color: "white",
              component: "span",
              variant: "largeTitle1"
            }, {
              children: e.replace(/0$/, "")
            }));
          },
          value: f,
          valueIsNumericString: !0
        }), y && r(l, i({
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          ml: 1
        }, {
          children: [a(m, {
            height: 7,
            src: s,
            width: 12
          }), a(m, {
            height: 7,
            src: s,
            width: 12
          })]
        }))]
      }))]
    })), a(d, {
      children: a("img", {
        alt: "",
        height: "100%",
        src: x,
        width: "100%"
      })
    })]
  }));
};

export { p as SuperRate };