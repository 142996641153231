import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var _styles;

  var theme = _ref.theme;
  var styles = (_styles = {
    display: 'flex',
    flex: '1'
  }, _defineProperty(_styles, theme.breakpoints.up('desktop'), {
    display: 'block'
  }), _defineProperty(_styles, "padding", 0), _styles);
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var _theme$colors, _theme$colors2;

  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.white,
    borderRadius: "".concat(pxToRem(16), " ").concat(pxToRem(16), " 0 0"),
    button: {
      margin: 0,
      minWidth: '100%'
    },
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    label: {
      color: theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.nightWizard,
      fontSize: pxToRem(16),
      fontWeight: 500
    },
    marginTop: theme.spacing(4),
    padding: theme.spacing(4, 2)
  };
  return styles;
});
var StyledPageWrapper = styled(Stack)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    flexGrow: '1',
    padding: theme.spacing(3, 0, 0)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledWidget = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.colors.panPurple,
    borderRadius: pxToRem(16),
    display: 'flex',
    height: pxToRem(60),
    justifyContent: 'center',
    width: pxToRem(60)
  };
  return styles;
});
var StyledLine = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    p: {
      fontSize: pxToRem(16),
      fontWeight: 500
    },
    padding: theme.spacing(6.5, 1, 4)
  };
  return styles;
});
export { StyledBox, StyledContainer, StyledLine, StyledPageWrapper, StyledWidget };