import { SET_MANY_CONSENTS, SET_NO_CONSENTS, SET_ONE_CONSENT } from './consent.action-types';
export var actions = {
  /**
   * Set consents action.
   *
   * @author Christina Kashevar
   * @function setConsent
   * @category Actions
   * @subcategory Consents
   * @return {SetManyConsents} action object.
   */
  setManyConsents: function setManyConsents() {
    return {
      type: SET_MANY_CONSENTS
    };
  },

  /**
   * Set consents action.
   *
   * @author Christina Kashevar
   * @function setConsent
   * @category Actions
   * @subcategory Consents
   * @return {SetTheme} action object.
   */
  setNoConsents: function setNoConsents() {
    return {
      type: SET_NO_CONSENTS
    };
  },

  /**
   * Set consents action.
   *
   * @author Christina Kashevar
   * @function setOneConsent
   * @category Actions
   * @subcategory Consents
   * @return {SetTheme} action object.
   */
  setOneConsent: function setOneConsent() {
    return {
      type: SET_ONE_CONSENT
    };
  }
};