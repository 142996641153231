import { __assign as o } from "../../_virtual/_tslib.js";
import { createTheme as a, alpha as t } from "@mui/material";
import "react";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import { convertToDVH as e } from "../../helpers/theme/theme.helper.js";
import { boxShadows as r } from "../box-shadows/boxShadows.js";
import { colors as n } from "../colors/colors.js";
import i from "../../packages/pwa-ui/assets/fonts/CeraPro-Bold.woff.js";
import l from "../../packages/pwa-ui/assets/fonts/CeraPro-Bold.woff2.js";
import s from "../../packages/pwa-ui/assets/fonts/CeraPro-Medium.woff.js";
import p from "../../packages/pwa-ui/assets/fonts/CeraPro-Medium.woff2.js";
import c from "../../packages/pwa-ui/assets/fonts/CeraPro-Regular.woff.js";
import d from "../../packages/pwa-ui/assets/fonts/CeraPro-Regular.woff2.js";
import { gradients as m } from "../gradients/gradients.js";
var g,
    f = a(),
    u = f.palette,
    h = f.typography.pxToRem,
    y = a({
  breakpoints: {
    values: {
      desktop: 1200,
      largeDesktop: 1264,
      lg: 1200,
      md: 768,
      mobile: 0,
      tablet: 768,
      xs: 0
    }
  },
  palette: {
    abandonedSpaceship: u.augmentColor({
      color: {
        contrastText: n.white,
        main: n.abandonedSpaceship
      }
    }),
    alpineGoat: u.augmentColor({
      color: {
        contrastText: n.white,
        dark: n.lavenderSyrup,
        main: n.alpineGoat
      }
    }),
    aragonGreen: u.augmentColor({
      color: {
        contrastText: n.white,
        main: n.aragonGreen
      }
    }),
    background: {
      default: n.white
    },
    bayFog: u.augmentColor({
      color: {
        contrastText: n.white,
        main: n.bayFog
      }
    }),
    brightGray: u.augmentColor({
      color: {
        main: n.brightGray
      }
    }),
    carteBlanche: u.augmentColor({
      color: {
        contrastText: n.panPurple,
        dark: n.panPurple,
        main: n.carteBlanche
      }
    }),
    error: {
      dark: n.etruscanRed,
      main: n.clownNose
    },
    estateViolet: u.augmentColor({
      color: {
        contrastText: n.white,
        main: n.estateViolet
      }
    }),
    ghostWhite: u.augmentColor({
      color: {
        main: n.ghostWhite
      }
    }),
    goldenHour: u.augmentColor({
      color: {
        main: n.goldenHour
      }
    }),
    hawaiianPassion: u.augmentColor({
      color: {
        contrastText: n.white,
        main: n.hawaiianPassion
      }
    }),
    lavenderSyrup: u.augmentColor({
      color: {
        contrastText: n.bayFog,
        dark: n.alpineGoat,
        main: n.lavenderSyrup
      }
    }),
    nightWizard: u.augmentColor({
      color: {
        contrastText: n.white,
        main: n.nightWizard
      }
    }),
    primary: {
      dark: n.denim,
      main: n.panPurple
    },
    purpleIllusion: u.augmentColor({
      color: {
        main: n.purpleIllusion
      }
    }),
    secondary: {
      contrastText: n.white,
      main: n.bayFog
    },
    text: {
      primary: n.nightWizard,
      secondary: n.bayFog
    },
    whisper: u.augmentColor({
      color: {
        main: n.whisper
      }
    }),
    white: u.augmentColor({
      color: {
        contrastText: n.nightWizard,
        dark: n.ghostWhite,
        main: n.white
      }
    }),
    whiteSolid: u.augmentColor({
      color: {
        contrastText: n.bayFog,
        dark: n.alpineGoat,
        main: n.whiteSolid
      }
    })
  }
}),
    b = y.palette,
    w = y.breakpoints,
    C = a({
  breakpoints: w,
  components: {
    MuiAppBar: {
      variants: [{
        props: {
          color: "default"
        },
        style: {
          backgroundColor: n.whiteSolid,
          color: b.text.primary
        }
      }]
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          border: "".concat(h(1), " solid ").concat(b.background.paper)
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: !1
      },
      variants: [{
        props: {
          disableGutters: !0
        },
        style: {
          paddingLeft: 0,
          paddingRight: 0
        }
      }, {
        props: {
          disableGutters: !1
        },
        style: (g = {
          paddingLeft: 16,
          paddingRight: 16
        }, g[w.up("tablet")] = {
          paddingLeft: 56,
          paddingRight: 56
        }, g)
      }]
    },
    MuiCssBaseline: {
      styleOverrides: "\n          * {\n            font-family: Cera Pro, Helvetica, Arial, sans-serif\n          }\n          *[hidden] {\n            display: none !important;\n            pointer-events: none;\n          }\n          input[type='password'] {\n            font-family: Verdana, monospace, auto, sans-serif, 'Cera Pro';\n            letter-spacing: 0.125em;\n          }\n          @font-face {\n            font-family: 'Cera Pro';\n            font-style: normal;\n            font-display: swap;\n            font-weight: 400;\n            src: local('CeraPro-Regular'), url(".concat(d, ") format('woff2'), url(").concat(c, ") format('woff');\n          }\n          @font-face {\n            font-family: 'Cera Pro';\n            font-style: normal;\n            font-display: swap;\n            font-weight: 500;\n            src: local('CeraPro-Medium'), url(").concat(p, ") format('woff2'), url(").concat(s, ") format('woff');\n          }\n          @font-face {\n            font-family: 'Cera Pro';\n            font-style: normal;\n            font-display: swap;\n            font-weight: 700;\n            src: local('CeraPro-Bold'), url(").concat(l, ") format('woff2'), url(").concat(i, ") format('woff');\n          }\n        ")
    },
    MuiIconButton: {
      defaultProps: {
        size: "medium"
      },
      variants: [{
        props: {
          color: "secondary",
          variant: "contained"
        },
        style: {
          ":hover": {
            backgroundColor: b.secondary.light,
            boxShadow: "0px ".concat(h(5), " ").concat(h(15), " ").concat(t(b.secondary.dark, .24))
          },
          backgroundColor: b.secondary.main
        }
      }, {
        props: {
          color: "primary",
          variant: "contained"
        },
        style: {
          ":hover": {
            backgroundColor: b.primary.light,
            boxShadow: "0px ".concat(h(5), " ").concat(h(15), " ").concat(t(b.primary.dark, .24))
          },
          backgroundColor: b.primary.main
        }
      }, {
        props: {
          color: "alpineGoat",
          variant: "contained"
        },
        style: {
          ":hover": {
            backgroundColor: b.alpineGoat.light,
            boxShadow: "0px ".concat(h(5), " ").concat(h(15), " ").concat(t(b.alpineGoat.dark, .24))
          },
          backgroundColor: b.alpineGoat.main
        }
      }]
    },
    MuiSkeleton: {
      variants: [{
        props: {
          variant: "rectangular"
        },
        style: {
          borderRadius: h(16)
        }
      }]
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "& .MuiSnackbarContent-root": {
            backgroundColor: b.nightWizard.main,
            borderRadius: h(12),
            boxShadow: "0px ".concat(h(5), " ").concat(h(20), " ").concat(t(n.independence, .03)),
            flexGrow: "initial",
            justifyContent: "center",
            minWidth: h(310)
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "auto",
          width: "auto"
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: "body1"
      }
    }
  },
  palette: o({}, b),
  shape: {
    borderRadius: 16
  },
  typography: {
    body1: {
      fontSize: h(16),
      letterSpacing: h(-.05),
      lineHeight: h(18)
    },
    body2: {
      fontSize: h(14),
      letterSpacing: h(-.02),
      lineHeight: h(16)
    },
    body3: {
      fontSize: h(13),
      letterSpacing: h(-.01),
      lineHeight: h(16)
    },
    button: void 0,
    caption: void 0,
    caption1: {
      fontSize: h(12),
      lineHeight: h(14)
    },
    caption2: {
      fontSize: h(10),
      lineHeight: h(12)
    },
    fontFamily: '"Cera Pro", Helvetica,Arial,sans-serif',
    h1: void 0,
    h2: void 0,
    h3: void 0,
    h4: void 0,
    h5: void 0,
    h6: void 0,
    largeTitle1: {
      fontSize: h(32),
      fontWeight: 700,
      lineHeight: h(40)
    },
    largeTitle2: {
      fontSize: h(28),
      fontWeight: 700,
      lineHeight: h(36)
    },
    overline: void 0,
    subtitle1: void 0,
    subtitle2: void 0,
    title1: {
      fontSize: h(24),
      lineHeight: h(26)
    },
    title2: {
      fontSize: h(20),
      letterSpacing: h(-.07),
      lineHeight: h(22)
    },
    title3: {
      fontSize: h(18),
      letterSpacing: h(-.05),
      lineHeight: h(20)
    }
  }
}, {
  boxShadows: r,
  colors: n,
  convertToDVH: e,
  gradients: m
});
export { C as defaultTheme, h as pxToRem };