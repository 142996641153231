import { ConstantsUtil } from '@utils';
import { Environment, EnvironmentMode } from '@enums';

/**
 * Gets the current server from ConstantsUtil.
 *
 * @author Yury Kuntsou
 * @function getAPPServer
 * @category helpers
 * @returns {string | undefined} The current server or undefined if not set.
 */
export var getAPPServer = function getAPPServer() {
  var server = ConstantsUtil.CURRENT_SERVER; // Map server value to one of the allowed values

  switch (server) {
    case Environment.Dev:
    case Environment.Preprod:
    case Environment.Prod:
    case Environment.Test:
      return server;

    default:
      return undefined;
  }
};
/**
 * Gets the current mode from ConstantsUtil.
 *
 * @author Yury Kuntsou
 * @function getAPPSMode
 * @category helpers
 * @returns {string | undefined} The current mode or undefined if not set.
 */

export var getAPPSMode = function getAPPSMode() {
  var mode = ConstantsUtil.CURRENT_MODE;

  switch (mode) {
    case EnvironmentMode.Dev:
    case EnvironmentMode.Prod:
      return mode;

    default:
      return undefined;
  }
};
/**
 * Checks if the given server is disabled.
 *
 * @author Yury Kuntsou
 * @function isDisableOnServer
 * @category helpers
 * @param {string|string[]} server - The server to check.
 * @returns {boolean | undefined} True if the server is disabled, false otherwise, or undefined if no server is set.
 */

export var isDisableOnServer = function isDisableOnServer(server) {
  var appServer = getAPPServer();
  if (!appServer) return;

  if (typeof server === 'string') {
    return server === getAPPServer();
  }

  return server.includes(appServer);
};
/**
 * Checks if user client number has access.
 *
 * @author Yury Kuntsou
 * @function isHasAccessToTestGroup
 * @category Utils
 * @param {string} uniqueClientNumber - unique client number.
 * @returns {boolean} checks if user client number has access.
 */

export var isHasAccessToTestGroup = function isHasAccessToTestGroup(uniqueClientNumber) {
  if (!uniqueClientNumber) {
    return false;
  }

  return !![// belveb
  '094OTD', '431198', '185831', '104BFE', '103G2L', '09752B', '11546E', '11546C', '223233', '589527', '612875', '251201', '326150', '600584', // vebtech
  '115D2F', '270185', '1190U7', '098GXW', '096RSD', '1029C0', '119R3H', '116NBO0', '095M78', '116NBO', '116RK9', '127U4N', '312815', '123A2E', '348708'].find(function (clientNumber) {
    return clientNumber.toLowerCase() === String(uniqueClientNumber).toLowerCase();
  });
};