import { __rest as e, __assign as t } from "../../_virtual/_tslib.js";
import { jsxs as r, jsx as o, Fragment as i } from "react/jsx-runtime";
import { Stack as n, Box as m } from "@mui/material";
import { CardsType as s } from "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import { DotsMenu as a } from "./elements/dots-menu/dots-menu.component.js";
import { CurrencyTypography as c } from "../currency-typography/currency-typography.component.js";
import { Typography as d } from "../typography/typography.component.js";
import { StyledCard as p, StyledCardContent as u } from "./card.styles.js";
import { AccountMoneyInfo as l } from "./elements/account-money-info/account-money-info.component.js";
import { Arrow as h } from "./elements/arrow/arrow.component.js";
import { CardTitle as g } from "./elements/card-title/card-title.component.js";
import "react";
import "../remote-image/remote-image.styles.js";
import "./elements/cards-images-info/cards-images-info.styles.js";
import { CreditDebitMoneyInfo as y } from "./elements/credit-debit-money-info/credit-debit-money-info.component.js";
import { Widget as f } from "./elements/widget/widget.component.js";

var b = function (b) {
  var j = b.title,
      v = b.currency,
      w = b.amount,
      A = b.secondCurr,
      T = b.secondAmount,
      C = b.thirdCurr,
      W = b.thirdAmount,
      D = b.cardsImgs,
      I = b.isWithArrow,
      x = b.subtext,
      N = b.date,
      $ = b.percent,
      k = b.variant,
      B = b.color,
      L = b.sum,
      H = b.cardType,
      M = b.height,
      O = b.isWithWidget,
      V = void 0 === O || O,
      z = b.cardNumber,
      _ = b.cardImage,
      F = b.titleCustomBlock,
      G = b.finishDate,
      q = b.isThreeDotsMenuVisible,
      E = b.handleOpenDrawer,
      J = b.productNumber,
      K = b.isHidden,
      P = b.dealType,
      Q = b.isLoading,
      R = e(b, ["title", "currency", "amount", "secondCurr", "secondAmount", "thirdCurr", "thirdAmount", "cardsImgs", "isWithArrow", "subtext", "date", "percent", "variant", "color", "sum", "cardType", "height", "isWithWidget", "cardNumber", "cardImage", "titleCustomBlock", "finishDate", "isThreeDotsMenuVisible", "handleOpenDrawer", "productNumber", "isHidden", "dealType", "isLoading"]);
  return r(p, t({}, R, {
    $height: M,
    $isTitle: Boolean(j),
    $variant: k,
    color: B,
    variant: k
  }, {
    children: [r(u, t({
      $isThreeDotsMenuVisible: q,
      $isTitle: Boolean(j),
      $isWithArrow: I,
      component: "span"
    }, {
      children: [j && o(g, {
        cardNumber: z,
        cardsImgs: D,
        color: B,
        percent: $,
        title: j,
        titleCustomBlock: F,
        variant: k
      }), r(n, t({
        alignItems: "center",
        component: "span",
        direction: "row",
        justifyContent: "space-between",
        spacing: 1.5
      }, {
        children: [V && o(f, {
          cardType: H,
          currency: v,
          image: _
        }), r(m, t({
          component: "span",
          flexGrow: 1,
          textAlign: "left"
        }, {
          children: [void 0 !== w && o(m, {
            children: o(c, {
              amount: w,
              color: Q ? "bayFog" : "nightWizard",
              currency: H !== s.Account ? v : void 0,
              fontWeightDecimal: 500,
              fontWeightInteger: 700,
              variantDecimal: "body2",
              variantInteger: "title2"
            })
          }), x && ("string" == typeof x ? o(d, t({
            color: "nightWizard",
            fontWeight: 700,
            variant: "title2"
          }, {
            children: x
          })) : x), H && r(i, {
            children: [(H === s.Account || H === s.Card) && o(l, {
              isLoading: Q,
              secondAmount: T,
              secondCurr: A,
              thirdAmount: W,
              thirdCurr: C
            }), H !== s.Account && H !== s.Card && o(y, {
              cardType: H,
              currency: v,
              date: N,
              finishDate: G,
              isLoading: Q,
              sum: L
            })]
          })]
        }))]
      }))]
    })), q && o(a, {
      dealType: P,
      handleOpenDrawer: E,
      isHidden: K,
      productNumber: J,
      productType: H
    }), I && o(h, {})]
  }));
};

export { b as Card };