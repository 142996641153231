import { createSelector } from 'reselect';

/**
 * Get Consent.
 *
 * @author Christina Kashevar
 * @function getTheme
 * @category Selectors
 * @subcategory Consent
 * @param {AppState} state state.
 * @return {ConsentNumber} ConsentNumber.
 */
var getConsent = function getConsent(state) {
  return state.consent;
};

export var getConsentSelector = createSelector(getConsent, function (consent) {
  return consent;
});