import { SET_HIDE_NOTIFICATION, SET_SHOW_NOTIFICATION } from './notification.action-types';
export var actions = {
  /**
   * Set theme action.
   *
   * @author Christina Kashevar
   * @function setHideNotifications
   * @category Actions
   * @subcategory Notifications
   * @return {SetHideNotification} action object.
   */
  setHideNotifications: function setHideNotifications() {
    return {
      type: SET_HIDE_NOTIFICATION
    };
  },

  /**
   * Set theme action.
   *
   * @author Christina Kashevar
   * @function setShowNotifications
   * @category Actions
   * @subcategory Notifications
   * @return {SetShowNotification} action object.
   */
  setShowNotifications: function setShowNotifications() {
    return {
      type: SET_SHOW_NOTIFICATION
    };
  }
};