import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  }, theme.breakpoints.down('desktop'), {
    maxWidth: 'unset',
    padding: 0
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative'
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    flex: 'unset',
    height: theme.typography.pxToRem(452),
    maxWidth: theme.typography.pxToRem(452),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    left: '50%',
    pointerEvents: 'none',
    position: 'absolute',
    top: 'calc(50% - 24px)',
    transform: 'translate(-50%, -50%)',
    width: pxToRem(240),
    zIndex: 2
  };
  return styles;
});
var StyledVideoWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme,
      $isInit = _ref4.$isInit;
  var styles = {
    '& > canvas': {// '& + div': {
      //   display: 'none !important',
      // },
      // aspectRatio: 'auto 375/375',
      // height:    '100% !important',
      // left:      0,
      // objectFit: 'contain',
      // position:  'absolute',
      // top:       0,
      // width:     '100% !important',
    },
    '& > div': {
      display: 'none !important'
    },
    '& > video': {
      backgroundColor: theme.palette.common.black,
      display: 'block',
      height: '100%',
      inset: 0,
      // left:   '50%',
      objectFit: 'cover',
      position: 'absolute',
      // top:       '50%',
      // transform: 'translate(-50%, -50%)',
      width: '100% !important'
    },
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flex: $isInit && 1,
    flexDirection: 'column',
    minHeight: $isInit && '400px',
    overflow: 'hidden',
    position: 'relative',
    width: '100%'
  };
  return styles;
});
export { StyledBox, StyledContainer, StyledVideoWrapper, StyledWrapper };