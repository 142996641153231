import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, Skeleton, styled } from '@mui/material';
var StyledSkeletonBox = styled(Skeleton)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    aspectRatio: '31/19',
    display: 'block',
    height: '100%',
    maxHeight: pxToRem(290),
    maxWidth: pxToRem(300),
    width: '100%'
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(6)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(6)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
export { StyledContainer, StyledContentWrapper, StyledSkeletonBox };