import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@store/auth';
import { CardsService } from '@services';
import { CardDeliveryMethod } from '@enums';
import { useApi } from '../use-api';

/**
 * Card issue hook.
 *
 * @author Christina Kashevar
 * @function useCardIssue
 * @category hook
 * @return {CardIssueProps} return card issue info.
 */
export var useCardIssue = function useCardIssue(productCode, pictureURL, isVirtual, innerState) {
  var user = useSelector(getUserSelector);

  var _useApi = useApi({
    api: CardsService.apiCardIssueStartGet,
    isVirtual: isVirtual
  }),
      data = _useApi.data,
      loading = _useApi.loading;

  var accounts = data === null || data === void 0 ? void 0 : data.accounts.map(function (account) {
    return _objectSpread(_objectSpread({}, account), {}, {
      id: account.iban
    });
  });
  var sortedAccounts = useMemo(function () {
    var newAccounts = data === null || data === void 0 ? void 0 : data.availableCurrency.reduce(function (acc, item) {
      var filteredAccounts = accounts === null || accounts === void 0 ? void 0 : accounts.filter(function (account) {
        return account.accountCurrency === (item === null || item === void 0 ? void 0 : item.nameCurr);
      });
      filteredAccounts && acc.push.apply(acc, _toConsumableArray(filteredAccounts));
      return acc;
    }, []);
    return newAccounts;
  }, [accounts, data === null || data === void 0 ? void 0 : data.availableCurrency]);
  var firstAccount = sortedAccounts === null || sortedAccounts === void 0 ? void 0 : sortedAccounts[0];
  var isAccountWithCurrencyFromCurrencyListForCard = Boolean(data === null || data === void 0 ? void 0 : data.availableCurrency.map(function (item) {
    return item === null || item === void 0 ? void 0 : item.nameCurr;
  }).includes((firstAccount === null || firstAccount === void 0 ? void 0 : firstAccount.accountCurrency) || ''));
  var showOpenAccount = innerState && Object.keys(innerState).length !== 0 && !innerState.iban;

  var getInitialAccount = function getInitialAccount() {
    if (innerState && Object.keys(innerState).length !== 0) {
      // get account from state
      if (innerState.iban) {
        return data === null || data === void 0 ? void 0 : data.accounts.find(function (account) {
          return account.iban === (innerState === null || innerState === void 0 ? void 0 : innerState.iban);
        });
      }

      return null;
    }

    return isAccountWithCurrencyFromCurrencyListForCard ? firstAccount : null;
  };

  var initialAccount = getInitialAccount();
  var deliveryValues = useMemo(function () {
    if (isVirtual) {
      return {};
    }

    return {
      apartmentNumber: user === null || user === void 0 ? void 0 : user.apartmentNumber,
      building: user === null || user === void 0 ? void 0 : user.building,
      delivery: CardDeliveryMethod.POST,
      district: {
        text: (user === null || user === void 0 ? void 0 : user.district) || '',
        value: (user === null || user === void 0 ? void 0 : user.district) || ''
      },
      houseNumber: user === null || user === void 0 ? void 0 : user.houseNumber,
      isShowDistrict: Boolean(user === null || user === void 0 ? void 0 : user.district),
      isShowLocality: Boolean(user === null || user === void 0 ? void 0 : user.localityName),
      locality: {
        text: (user === null || user === void 0 ? void 0 : user.localityName) || '',
        value: (user === null || user === void 0 ? void 0 : user.localityName) || ''
      },
      localityType: user === null || user === void 0 ? void 0 : user.localityType,
      officeCode: {
        text: '',
        value: ''
      },
      postCode: user === null || user === void 0 ? void 0 : user.postCode,
      region: {
        text: (user === null || user === void 0 ? void 0 : user.region) || (user === null || user === void 0 ? void 0 : user.localityName) || '',
        value: (user === null || user === void 0 ? void 0 : user.region) || (user === null || user === void 0 ? void 0 : user.localityName) || ''
      },
      soato: user === null || user === void 0 ? void 0 : user.soato,
      streetName: user === null || user === void 0 ? void 0 : user.streetName,
      streetType: {
        text: (user === null || user === void 0 ? void 0 : user.streetType) || '',
        value: (user === null || user === void 0 ? void 0 : user.streetType) || ''
      }
    };
  }, [isVirtual, user === null || user === void 0 ? void 0 : user.apartmentNumber, user === null || user === void 0 ? void 0 : user.building, user === null || user === void 0 ? void 0 : user.district, user === null || user === void 0 ? void 0 : user.houseNumber, user === null || user === void 0 ? void 0 : user.localityName, user === null || user === void 0 ? void 0 : user.localityType, user === null || user === void 0 ? void 0 : user.postCode, user === null || user === void 0 ? void 0 : user.region, user === null || user === void 0 ? void 0 : user.soato, user === null || user === void 0 ? void 0 : user.streetName, user === null || user === void 0 ? void 0 : user.streetType]);
  var virtValues = useMemo(function () {
    if (isVirtual) {
      return {
        checkSMSAgreement: true,
        isAddSMS: false,
        pictureURL: pictureURL
      };
    }
  }, [isVirtual, pictureURL]);
  var initialValues = useMemo(function () {
    var _data$availableCurren;

    return _objectSpread(_objectSpread(_objectSpread({}, deliveryValues), virtValues), {}, {
      accountABANAS: isAccountWithCurrencyFromCurrencyListForCard ? initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.accountABANAS : undefined,
      accountCurrency: isAccountWithCurrencyFromCurrencyListForCard ? (initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.accountCurrency) || (innerState === null || innerState === void 0 ? void 0 : innerState.currency) : data === null || data === void 0 ? void 0 : (_data$availableCurren = data.availableCurrency[0]) === null || _data$availableCurren === void 0 ? void 0 : _data$availableCurren.nameCurr,
      accountForCommission: null,
      accountId: isAccountWithCurrencyFromCurrencyListForCard ? initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.iban : undefined,
      cardProductCode: productCode,
      cardholderName: (innerState === null || innerState === void 0 ? void 0 : innerState.cardholderName) || '',
      cardholderSurname: (innerState === null || innerState === void 0 ? void 0 : innerState.cardholderSurname) || '',
      checkAgreement: true,
      checkContract: true,
      checkPersonalData: true,
      codeWord: (innerState === null || innerState === void 0 ? void 0 : innerState.codeWord) || '',
      iban: isAccountWithCurrencyFromCurrencyListForCard ? initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.iban : undefined,
      isWithAccountOpen: !isAccountWithCurrencyFromCurrencyListForCard || showOpenAccount,
      isWithCommission: !(initialAccount !== null && initialAccount !== void 0 && initialAccount.accountABANAS)
    });
  }, [deliveryValues, virtValues, isAccountWithCurrencyFromCurrencyListForCard, initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.accountABANAS, initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.accountCurrency, initialAccount === null || initialAccount === void 0 ? void 0 : initialAccount.iban, innerState === null || innerState === void 0 ? void 0 : innerState.currency, innerState === null || innerState === void 0 ? void 0 : innerState.cardholderName, innerState === null || innerState === void 0 ? void 0 : innerState.cardholderSurname, innerState === null || innerState === void 0 ? void 0 : innerState.codeWord, data === null || data === void 0 ? void 0 : data.availableCurrency, productCode, showOpenAccount]);
  return {
    accounts: sortedAccounts,
    availableCurrency: data === null || data === void 0 ? void 0 : data.availableCurrency,
    initialValues: initialValues,
    isOpenNewAccountAllowed: data === null || data === void 0 ? void 0 : data.isOpenNewAccountAllowed,
    loading: loading
  };
};