export var Routes;

(function (Routes) {
  Routes["All"] = "*";
  Routes["Main"] = "/";
  Routes["Error"] = "/error";
  Routes["Success"] = "/success";
  Routes["Login"] = "/login";
  Routes["SignUp"] = "/register";
  Routes["Home"] = "/home";
  Routes["Payslips"] = "/payslips";
  Routes["Payments"] = "/payments";
  Routes["PaymentsPay"] = "/payments/pay";
  Routes["PaymentMultiple"] = "/payments/multiple";
  Routes["PaymentOneclick"] = "/payments/oneclick";
  Routes["PaymentFavourite"] = "/payments/favourite";
  Routes["PaymentAccounts"] = "/payments/accounts";
  Routes["PaymentPhone"] = "/payments/phone";
  Routes["PaymentPhoneDetails"] = "/payments/phone/details";
  Routes["PaymentCards"] = "/payments/cards";
  Routes["PaymentCardsP2P"] = "/payments/cards/p2p";
  Routes["PaymentErip"] = "/payments/erip";
  Routes["PaymentPopular"] = "/payments/popular";
  Routes["PaymentFree"] = "/payments/free";
  Routes["PaymentQRCode"] = "/payments/qr-code";
  Routes["Exchange"] = "/exchange";
  Routes["ExchangeDetails"] = "/exchange/details";
  Routes["Account"] = "/account/:iban";
  Routes["OpenAccount"] = "/account/open";
  Routes["OpenAccounts"] = "/accounts/open";
  Routes["Card"] = "/card/:extraRsTech";
  Routes["CardLimits"] = "/card/:extraRsRelated/:cardStatus/:currency/card-limits";
  Routes["CardIssue"] = "/card/issue";
  Routes["CardVirtualIssue"] = "/card/virtual/issue";
  Routes["Credit"] = "/credit/:contractNumber";
  Routes["CreditCalculator"] = "/credit/calculator";
  Routes["CreditPersonalInfo"] = "/credit/personal-info";
  Routes["CreditApplication"] = "/credit/application";
  Routes["Deposit"] = "/deposit/:id/:dealCode/:dealType";
  Routes["DepositClose"] = "/deposit/:id/close";
  Routes["DepositCalculator"] = "/deposit/calculator";
  Routes["More"] = "/more";
  Routes["About"] = "/about";
  Routes["History"] = "/history";
  Routes["Package"] = "/package";
  Routes["OpenPackage"] = "/package/open";
  Routes["UpgradePackage"] = "/package/upgrade";
  Routes["Settings"] = "/settings";
  Routes["SettingsPassword"] = "/settings/password";
  Routes["SettingsPin"] = "/settings/pin";
  Routes["SettingsSecurity"] = "/settings/security";
  Routes["ProductsOrder"] = "/settings/products-order";
  Routes["SettingsInstantTransfer"] = "/settings/instant-transfer";
  Routes["InstantTransfer"] = "/instant-transfer";
  Routes["Help"] = "/help";
  Routes["BankOnMap"] = "/bank-on-map";
  Routes["DeepLink"] = "/deep-link";
  Routes["Consents"] = "/consents";
})(Routes || (Routes = {}));