import { createSelector } from 'reselect';

/**
 * Get theme name.
 *
 * @author Christina Kashevar
 * @function getNotifications
 * @category Selectors
 * @subcategory Notifications
 * @param {AppState} state state.
 * @return {Theme} theme.
 */
var getNotifications = function getNotifications(state) {
  return state.notification;
};

export var getNotificationSelector = createSelector(getNotifications, function (notification) {
  return notification;
});