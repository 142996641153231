import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { alpha, Box, Container, styled } from '@mui/material';
import { NotificationImage } from '@enums';
var StyledImageWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.whisper.main,
    display: 'flex',
    flex: '0 0',
    justifyContent: 'center',
    position: 'relative'
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    position: 'unset'
  });

  return styles;
});
var StyledBackgroundImage = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $backgroundImage = _ref2.$backgroundImage;

  var styles = _defineProperty({
    backgroundImage: "url(".concat($backgroundImage, ")"),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: "blur(".concat(theme.typography.pxToRem(10), ") contrast(0.5)"),
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%'
  }, theme.breakpoints.up('desktop'), {
    display: 'none'
  });

  return styles;
});
var StyledImage = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var pxToRem = theme.typography.pxToRem;

  var styles = _defineProperty({
    '& > div': {
      height: '100%'
    },
    aspectRatio: "".concat(NotificationImage.RatioWidth, "/").concat(NotificationImage.RatioHeight),
    boxShadow: "0px ".concat(pxToRem(10), " 0px ").concat(alpha(theme.palette.white.main, 0.25)),
    height: '100%',
    maxHeight: pxToRem(390),
    position: 'relative',
    width: '100%'
  }, theme.breakpoints.up('desktop'), {
    maxWidth: pxToRem(452)
  });

  return styles;
});
var StyledContent = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: pxToRem(30),
    borderTopRightRadius: pxToRem(30),
    marginTop: theme.spacing(-4),
    position: 'relative',
    zIndex: 1
  }, theme.breakpoints.up('desktop'), {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: 0
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    marginTop: theme.spacing(-3),
    overflowY: 'auto'
  }, theme.breakpoints.up('desktop'), {
    marginTop: 0,
    overflow: 'unset'
  });

  return styles;
});
var StyledIconWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme,
      isDesktopBreakpoint = _ref6.isDesktopBreakpoint;

  var styles = _defineProperty({
    backgroundColor: !isDesktopBreakpoint ? theme.palette.white.main : 'transparent',
    borderRadius: '100%',
    opacity: !isDesktopBreakpoint ? 0.8 : 1,
    position: 'fixed',
    right: pxToRem(12),
    top: pxToRem(16),
    zIndex: 2
  }, theme.breakpoints.up('desktop'), {
    right: '26%'
  });

  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(0, 0, 4),
    position: 'relative',
    zIndex: 1
  };
  return styles;
});
var StyledText = styled(Box)(function (_ref8) {
  var theme = _ref8.theme;
  var styles = {
    '& p': {
      lineHeight: pxToRem(21)
    },
    '& p:last-child': {
      marginBottom: theme.spacing(3)
    },
    paddingTop: theme.spacing(4)
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref9) {
  var theme = _ref9.theme;

  var styles = _defineProperty({
    padding: theme.spacing(0, 2.5)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: pxToRem(452),
    padding: 0
  });

  return styles;
});
var StyledGradient = styled(Box)(function () {
  var styles = {
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    height: pxToRem(32),
    position: 'absolute',
    top: pxToRem(-32),
    width: '100vw'
  };
  return styles;
});
export { StyledBackgroundImage, StyledButtonsWrapper, StyledContainer, StyledContent, StyledGradient, StyledIconWrapper, StyledImage, StyledImageWrapper, StyledText, StyledWrapper };