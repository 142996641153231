import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserHasAccountsSelector, getUserHasPackageSelector } from '@store/auth';
import { NotificationService } from '@services';
import { Routes } from '@enums';

/**
 * Show consents error hook.
 *
 * @author Christina Kashevar
 * @function useConsentsError
 * @category hook
 * @return {} return void.
 */
export var useHandleConsentError = function useHandleConsentError(error, route) {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var navigate = useNavigate();
  var userHasAccounts = useSelector(getUserHasAccountsSelector);
  var userHasPackage = useSelector(getUserHasPackageSelector);
  var isResidentNoAccounts = !userHasPackage && !userHasAccounts;
  useEffect(function () {
    if (error) {
      var err = error;

      if (isResidentNoAccounts) {
        navigate(Routes.Error, {
          replace: true,
          state: {
            mainButton: {
              text: t('pages.error.buttonBack')
            },
            message: err.message,
            secondaryButton: {
              to: null
            }
          }
        });
      } else if (route === Routes.Home) {
        navigate(Routes.Error, {
          replace: true,
          state: {
            mainButton: {
              to: Routes.Home
            },
            message: err.message
          }
        });
        setTimeout(function () {
          NotificationService.setShowNotifications();
        }, 100);
      } else {
        navigate(Routes.Error, {
          replace: true,
          state: {
            message: err.message,
            route: route
          }
        });
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [error, route]);
};