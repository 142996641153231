import { Box, styled } from '@mui/material';
var StyledModalContentContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $enableScrollShadow = _ref.$enableScrollShadow;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: $enableScrollShadow && 'hidden',
    position: 'relative'
  };
  return styles;
});
var StyledHeader = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    height: theme.HEADER_HEIGHT
  };
  return styles;
});
var StyledContent = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var height = "calc(".concat(theme.convertToDVH(100), " - ").concat(theme.HEADER_HEIGHT, ")");
  var styles = {
    height: height
  };
  return styles;
});
export { StyledContent, StyledHeader, StyledModalContentContainer };