import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    '& div p': {
      color: theme.colors.nightWizard
    },
    '& div strong': {
      color: theme.colors.nightWizard
    },
    display: 'flex',
    flexGrow: '1',
    paddingBottom: theme.spacing(5)
  }, theme.breakpoints.up('desktop'), {
    // maxWidth:   theme.typography.pxToRem(1152),
    paddingTop: theme.spacing(2)
  });

  return styles;
});
var StyledContainer = styled(Container)(function () {
  var styles = {
    display: 'flex',
    flexGrow: '1'
  };
  return styles;
});
var StyledStack = styled(Stack)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    height: "calc(".concat(theme.convertToDVH(100), " - ").concat(theme.HEADER_HEIGHT, " - ").concat(theme.spacing(5), ")")
  }, theme.breakpoints.up('desktop'), {
    height: "calc(".concat(theme.convertToDVH(100), " - ").concat(theme.spacing(13), " - ").concat(theme.spacing(8), ")") // header - 104px

  });

  return styles;
});
export { StyledContainer, StyledStack, StyledWrapper };