import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button } from '@pwa/ui';
import { Stack, styled } from '@mui/material';
var StyledButton = styled(Button)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    padding: theme.spacing(0)
  };
  return styles;
});
var StyledContent = styled(Stack)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    '& div p': {
      color: theme.colors.nightWizard
    },
    '& div strong': {
      color: theme.colors.nightWizard
    },
    '& table': {
      borderSpacing: 0
    },
    '& td': {
      padding: 0
    },
    marginBottom: theme.spacing(5.5),
    marginTop: theme.spacing(-2)
  }, theme.breakpoints.up('tablet'), {
    marginTop: theme.spacing(0)
  });

  return styles;
});
var StyledButtons = styled(Stack)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    marginBottom: theme.spacing(3)
  };
  return styles;
});
var StyledButtonLink = styled(Button)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    alignSelf: 'start',
    height: 'unset',
    minWidth: 'unset',
    padding: theme.spacing(0)
  };
  return styles;
});
export { StyledButton, StyledButtonLink, StyledButtons, StyledContent };