import { styled as t, Box as e } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as i } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { Button as o } from "../button/button.component.js";
var n = t(o)(function (t) {
  var e = t.theme,
      o = t.$height,
      n = void 0 === o ? 104 : o,
      r = t.$variant,
      s = t.$isTitle,
      a = s && "contained" === r ? 1.75 : s && "contained" !== r ? 1.625 : "contained" === r ? 2 : 1.875;
  return {
    borderRadius: i(16),
    fontSize: 0,
    height: "string" == typeof n ? n : i(n),
    padding: e.spacing("contained" === r ? 2 : 1.875),
    paddingTop: e.spacing(a),
    textAlign: "left",
    width: "100%"
  };
}),
    r = t(e)(function (t) {
  t.theme;
  var e = t.$isWithArrow,
      i = t.$isThreeDotsMenuVisible;
  return {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    height: "100%",
    justifyContent: t.$isTitle ? "space-between" : "center",
    maxWidth: "calc(100% - ".concat(e ? "28px" : i ? "36px" : "0px", ")")
  };
});
export { n as StyledCard, r as StyledCardContent };