import { createSelector } from 'reselect';

/**
 * Get modals array.
 *
 * @author Ihar Kazlouski
 * @function getModals
 * @category Selectors
 * @subcategory Modals
 * @param {AppState} state state.
 * @return {ModalsState[]} modals state.
 */
var getModals = function getModals(state) {
  return state.modals;
};

export var getModalsSelector = createSelector(getModals, function (modals) {
  return modals;
});
export var getModalSelector = function getModalSelector(modalId) {
  return createSelector(getModals, function (modals) {
    return modals.find(function (modal) {
      return modal.modalId === modalId;
    });
  });
};
export var getModalDataSelector = function getModalDataSelector(modalId) {
  return createSelector([getModalSelector(modalId)], function (modal) {
    return (modal === null || modal === void 0 ? void 0 : modal.data) || undefined;
  });
};
export var getModalOpenStateSelector = function getModalOpenStateSelector(modalId) {
  return createSelector([getModalSelector(modalId)], function (modal) {
    return (modal === null || modal === void 0 ? void 0 : modal.isOpen) || false;
  });
};
export var getModalCloseAcceptedStateSelector = function getModalCloseAcceptedStateSelector(modalId) {
  return createSelector([getModalSelector(modalId)], function (modal) {
    return (modal === null || modal === void 0 ? void 0 : modal.isDisabledCloseAccepted) || false;
  });
};
export var getModalDialogPropsSelector = function getModalDialogPropsSelector(modalId) {
  return createSelector([getModalSelector(modalId)], function (modal) {
    return (modal === null || modal === void 0 ? void 0 : modal.dialogProps) || {};
  });
};