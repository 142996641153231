import { __assign as e } from "../../../../_virtual/_tslib.js";
import { jsx as r, jsxs as t } from "react/jsx-runtime";
import { NumericFormat as o } from "react-number-format";
import { Box as a } from "@mui/material";
import { Typography as n } from "../../../typography/typography.component.js";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as i } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
import { CardsImagesInfo as m } from "../cards-images-info/cards-images-info.component.js";
import { CardsNumber as s } from "../cards-number/cards-number.component.js";

var c = function (c) {
  var l = c.title,
      p = c.cardsImgs,
      d = c.percent,
      h = c.color,
      f = c.variant,
      u = c.cardNumber,
      v = c.titleCustomBlock,
      g = p && 0 !== p.length || u || v ? i(14) : i(24);
  return r(a, e({
    component: "span",
    display: "block",
    minHeight: 24
  }, {
    children: t(a, e({
      alignItems: "center",
      component: "span",
      display: "flex",
      justifyContent: "space-between"
    }, {
      children: [r(n, e({
        color: "secondary",
        component: "span",
        lineHeight: g,
        overflow: "hidden",
        textOverflow: "ellipsis",
        variant: "body2",
        whiteSpace: "nowrap"
      }, {
        children: l
      })), p && 0 !== p.length && r(m, {
        cardsImgs: p,
        color: h,
        variant: f
      }), void 0 !== d && r(a, e({
        component: "span",
        display: "flex",
        ml: .5
      }, {
        children: r(o, {
          decimalScale: void 0,
          decimalSeparator: ",",
          displayType: "text",
          fixedDecimalScale: !0,
          readOnly: !0,
          renderText: function (t) {
            if (void 0 !== t) return r(n, e({
              color: "secondary",
              variant: "caption1"
            }, {
              children: "".concat(t, "%")
            }));
          },
          thousandSeparator: " ",
          value: +d,
          valueIsNumericString: !0
        })
      })), void 0 !== u && r(s, {
        color: h,
        number: u,
        variant: f
      }), v && r(a, e({
        component: "span",
        display: "flex",
        ml: .5
      }, {
        children: v
      }))]
    }))
  }));
};

export { c as CardTitle };