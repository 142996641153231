import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import consentReducer from './consent/consent.reducer';
import dataReducer from './data/data.reducers';
import drawersReducer from './drawers/drawers.reducers';
import formReducer from './forms/forms.reducers';
import gridsReducer from './grids/grids.reducers';
import loaderReducer from './loader/loader.reducer';
import modalsReducer from './modals/modals.reducers';
import notificationReducer from './notification/notification.reducer';
import popupsReducer from './popups/popups.reducers';
import themeReducer from './theme/theme.reducer';
var rootReducer = combineReducers({
  auth: authReducer,
  consent: consentReducer,
  data: dataReducer,
  drawers: drawersReducer,
  forms: formReducer,
  grids: gridsReducer,
  loader: loaderReducer,
  modals: modalsReducer,
  notification: notificationReducer,
  popups: popupsReducer,
  theme: themeReducer
});
export default rootReducer;