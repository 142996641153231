import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button } from '@pwa/ui';
import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledItem = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& div p': {
      color: theme.colors.nightWizard
    },
    '& div strong': {
      color: theme.colors.nightWizard
    },
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(2.5, 2)
  };
  return styles;
});
var StyledButtonLink = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    alignSelf: 'start',
    height: 'unset',
    minWidth: 'unset',
    padding: theme.spacing(0)
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    minWidth: 'unset'
  };
  return styles;
});
export { StyledButton, StyledButtonLink, StyledContentWrapper, StyledItem };