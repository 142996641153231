import { ConsentNumber } from '@enums';
import { SET_MANY_CONSENTS, SET_NO_CONSENTS, SET_ONE_CONSENT } from './consent.action-types';
var initialState = ConsentNumber.OneConsent;
/**
 * Theme reducer.
 *
 * @author Yulian Kavaliou
 * @category Reducers
 * @subcategory Theme
 * @param {Theme} state theme state.
 * @param {ThemeActions} action theme action.
 * @return {Theme} theme state.
 */

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_ONE_CONSENT:
      {
        return ConsentNumber.OneConsent;
      }

    case SET_MANY_CONSENTS:
      {
        return ConsentNumber.ManyConsents;
      }

    case SET_NO_CONSENTS:
      {
        return ConsentNumber.NoConsents;
      }

    default:
      {
        return state;
      }
  }
});