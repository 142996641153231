import { SET_HIDE_NOTIFICATION, SET_SHOW_NOTIFICATION } from './notification.action-types';
var initialState = true;
/**
 * Notification reducer.
 *
 * @author Christina Kashevar
 * @category Reducers
 * @subcategory Notification
 * @return {boolean} Notification state.
 */

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_SHOW_NOTIFICATION:
      {
        return true;
      }

    case SET_HIDE_NOTIFICATION:
      {
        return false;
      }

    default:
      {
        return state;
      }
  }
});